import Vue from 'vue';
import VueRouter from 'vue-router';
import Delivery from '@/views/Delivery.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/delivery',
    name: 'delivery',
    component: Delivery,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
