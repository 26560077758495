import Axios from 'axios';
import { GettersUtility, MutationsUtility } from '@afrigis/vuex-utilities';
import {
  addressDetails,
  addressSearch,
} from '@afrigis/aws-search-services';

const STATEVAR_AXIOSCANCELSOURCE = 'autocompleteAxiosCancelSource';
const STATEVAR_DETAILEDRESULTS = 'detailedResults';
const STATEVAR_KEY = 'key';
const STATEVAR_LASTENTEREDTEXT = 'lastEnteredText';
const STATEVAR_LOADINGSUGGESTIONS = 'loadingSuggestions';
const STATEVAR_SUGGESTIONS = 'suggestions';
const STATEVAR_TOKEN = 'token';
const STATEVAR_DELIVERY_RESULTS = 'deliveryResults';

const MUTATION_SET_LOADINGSUGGESTIONS = 'loadingSuggestions';
const MUTATION_SET_SUGGESTIONS = 'commitSuggestions';
const MUTATION_CANCEL_AUTOCOMPLETE = 'cancelAutocomplete';
const MUTATION_SET_AXIOS_CANCELSOURCE = 'autocompleteAxiosCancelSource';
const MUTATION_SET_KEY = 'SetKey';
const MUTATION_SET_SEARCHRESULTS = 'commitDetailedResults';
const MUTATION_SET_TOKEN = 'commitToken';
const MUTATION_SET_DELIVERY_RESULTS = 'SetDeliveryResults';

const ACTION_DOSEARCH = 'doSearch';
const ACTION_SEARCHADDRESS = 'searchForAddressString';

const PROXY_URL = `${process.env.VUE_APP_ROOT_API}/proxies/search/api`;

const state = {
  [STATEVAR_AXIOSCANCELSOURCE]: null,
  [STATEVAR_DETAILEDRESULTS]: null,
  [STATEVAR_KEY]: null,
  [STATEVAR_LASTENTEREDTEXT]: null,
  [STATEVAR_LOADINGSUGGESTIONS]: false,
  [STATEVAR_SUGGESTIONS]: null,
  [STATEVAR_TOKEN]: null,
  [STATEVAR_DELIVERY_RESULTS]: null,
};

function awsAxiosInstance(token, key) {
  const axiosInstance = Axios.create();
  axiosInstance.interceptors.request
    .use(
      (config) => {
        const authorisationHeader = `Bearer ${token}`;
        const { headers } = config;
        headers.Authorization = authorisationHeader;
        headers.common['x-api-key'] = key;
        headers.common['Access-Control-Allow-Origin'] = '*';
        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );
  return axiosInstance;
}

const actions = {
  doAutocomplete: (context, searchText) => new Promise((resolve, reject) => {
    context.commit(MUTATION_SET_LOADINGSUGGESTIONS, true);
    context.commit(MUTATION_SET_SUGGESTIONS, null);

    context.commit(MUTATION_CANCEL_AUTOCOMPLETE);
    context.commit(MUTATION_SET_AXIOS_CANCELSOURCE, Axios.CancelToken.source());

    const autocompleteUrl = 'https://afrigis.services/places-autocomplete/api/v3/autocomplete';

    const axiosInstance = awsAxiosInstance(
      context.state[STATEVAR_TOKEN],
      context.state[STATEVAR_KEY],
    );

    const encodedSearch = encodeURIComponent(searchText);
    axiosInstance({
      url: `${autocompleteUrl}?query=${encodedSearch}`,
      cancelToken: state.autocompleteAxiosCancelSource.token,
    }).then((result) => {
      const mappedResult = result.data.result.map((r) => ({
        ...r,
        type: 'address',
      }));
      context.commit(MUTATION_SET_SUGGESTIONS, mappedResult);
      context.commit(MUTATION_SET_LOADINGSUGGESTIONS, false);
      resolve();
    }).catch(() => {
      reject();
    });
  }),
  doSearch: ({ dispatch }, payload) => new Promise(() => dispatch(ACTION_SEARCHADDRESS, payload)),
  searchLastEnteredText: ({ dispatch }) => new Promise(() => (
    dispatch(ACTION_DOSEARCH, state.lastEnteredText)
  )),
  doSearchDetail: async (context, seoid) => {
    const axiosInstance = awsAxiosInstance(
      context.state[STATEVAR_TOKEN],
      context.state[STATEVAR_KEY],
    );
    try {
      const { data: { code, message, result } } = await addressDetails(
        axiosInstance,
        seoid,
      );

      if (code !== 200) {
        throw new Error(message);
      }

      context.commit(MUTATION_SET_SEARCHRESULTS, result);
    } catch (error) {
      const errorDescription = (error.response && error.response.data)
        ? error.response.data.message
        : error.message;
      throw new Error(errorDescription);
    }
  },
  doDelivery: (context, seoid) => new Promise((resolve, reject) => {
    context.commit(MUTATION_SET_DELIVERY_RESULTS, null);
    const axiosInstance = awsAxiosInstance(
      context.state[STATEVAR_TOKEN],
      context.state[STATEVAR_KEY],
    );
    const deliveryUrl = 'https://afrigis.services/places-delivery/api/v1/delivery';
    axiosInstance.get(`${deliveryUrl}?reference=${seoid}`)
      .then((response) => {
        context.commit(MUTATION_SET_DELIVERY_RESULTS, response.data.result);
        resolve();
      })
      .catch(() => {
        reject();
      });
  }),
  fetchAwsCredentials: (context) => new Promise((resolve, reject) => {
    if (context.state[STATEVAR_TOKEN] && context.state[STATEVAR_KEY]) {
      return;
    }
    const requestObj = {
      url: `${PROXY_URL}/awsauthentication/`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    Axios(requestObj)
      .then((response) => {
        const {
          token,
          key,
        } = response.data.result;
        context.commit(MUTATION_SET_TOKEN, token);
        context.commit(MUTATION_SET_KEY, key);
        resolve();
      })
      .catch(() => {
        reject();
      });
  }),
  searchForAddressString: async (context, searchText) => {
    context.commit(MUTATION_SET_LOADINGSUGGESTIONS, true);
    context.commit(MUTATION_SET_SUGGESTIONS, null);
    const axiosInstance = awsAxiosInstance(
      context.state[STATEVAR_TOKEN],
      context.state[STATEVAR_KEY],
    );
    try {
      const { data: { code, message, result } } = await addressSearch(
        axiosInstance,
        searchText,
      );

      if (code !== 200) {
        throw new Error(message);
      }

      const suggestion = result.map((r) => (
        {
          seoid: r.seoid,
          description: r.formatted_address,
          latitude: r.location.lat,
          longitude: r.location.lng,
          type: 'address',
        }
      ));

      context.commit(MUTATION_SET_SUGGESTIONS, suggestion);
    } catch (error) {
      const errorDescription = (error.response && error.response.data)
        ? error.response.data.message
        : error.message;
      throw new Error(errorDescription);
    } finally {
      context.commit(MUTATION_SET_LOADINGSUGGESTIONS, false);
    }
  },
};

const getters = {
  detailedResults: GettersUtility.getObjectCopy(STATEVAR_DETAILEDRESULTS),
  key: GettersUtility.get(STATEVAR_KEY),
  loadingSuggestions: GettersUtility.get(STATEVAR_LOADINGSUGGESTIONS),
  suggestions: GettersUtility.getArrayCopy(STATEVAR_SUGGESTIONS),
  token: GettersUtility.get(STATEVAR_TOKEN),
  deliveryResults: GettersUtility.getObjectCopy(STATEVAR_DELIVERY_RESULTS),
};

const mutations = {
  autocompleteAxiosCancelSource: MutationsUtility.set(STATEVAR_AXIOSCANCELSOURCE),
  cancelAutocomplete: (stateP) => {
    if (stateP.autocompleteAxiosCancelSource) {
      const localState = stateP;
      localState.autocompleteAxiosCancelSource.cancel();
    }
  },
  clearDetailedResults: (stateP) => {
    const localState = stateP;
    localState.detailedResults = null;
  },
  clearSuggestions: (stateP) => {
    const localState = stateP;
    localState.suggestions = null;
  },
  commitToken: MutationsUtility.set(STATEVAR_TOKEN),
  commitSuggestions: MutationsUtility.set(STATEVAR_SUGGESTIONS),
  commitDetailedResults: MutationsUtility.set(STATEVAR_DETAILEDRESULTS),
  loadingSuggestions: MutationsUtility.set(STATEVAR_LOADINGSUGGESTIONS),
  LastEnteredText: MutationsUtility.set(STATEVAR_LASTENTEREDTEXT),
  [MUTATION_SET_KEY]: MutationsUtility.set(STATEVAR_KEY),
  [MUTATION_SET_DELIVERY_RESULTS]: MutationsUtility.set(STATEVAR_DELIVERY_RESULTS),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
