<template>
  <b-container fluid>
    <b-row>
      <b-col md="5" class="p-3 text-left">
        <h1 class="headerText">Delivery Address Formatting API</h1>
      </b-col>
      <b-col md="4" class="ml-auto p-3 text-right appCtrlButtons">
        <b-button
          title="Share This App"
          variant="outline-secondary"
          @click="shareViaWebShare"
        >
          <b-icon-share />
        </b-button>
        <b-button @click="startTour" variant="outline-secondary">
          Start Tour
        </b-button>
      </b-col>
    </b-row>
    <contact-expert />
    <b-row>
      <!--Column 1 -->
      <b-col class="mb-3" lg="4">
        <b-card class="mb-3 resultBackground" header-tag="header">
          <template #header>
            <h4>Interact with Delivery Address Formatting API</h4>
          </template>
          <h5>Find an address</h5>
          <form autocomplete="off" @submit.prevent="submitSearch">
            <ag-autocomplete
              autofocus
              id="autocomplete"
              class="autocomplete-component"
              :options="suggestions"
              placeholder="Address"
              :processing="loadingSuggestions"
              :text="searchText"
              @cleared="clearAddress"
              @onChosen="onChosen"
              @onFocus="fetchAwsCredentials"
              @onInput="startAutocomplete"
              @onSearch="startSearch"
            />
          </form>
          <b-alert
            class="mt-5"
            show
            v-if="hasError"
            variant="danger"
            dismissible
          >
            {{ searchError }}
          </b-alert>
          <b-row class="mt-5">
            <b-col id="result">
              <h5>Results</h5>
              <delivery-details
                v-if="!!deliveryResults"
                :deliveryDetails="deliveryResults.delivery"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <!--Column 2 -->
      <b-col class="mb-3" lg="4">
        <b-card
          id="trust-us-card"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
          text-variant="white"
          class="mb-3"
        >
          <div class="content">
            <h5>Trust us for</h5>
            <ul>
              <li>SABS and ISO compliant Address data.</li>
              <li>Accurate and verified address results returned.</li>
              <li>
                Components available via API to integrate into your own
                solutions.
              </li>
              <li>Over 50 Million searchable address combinations.</li>
            </ul>
          </div>
        </b-card>
        <b-card id="possible-uses-card">
          <h5>Possible uses:</h5>
          <ul>
            <li>Generate accurate delivery address labels.</li>
            <li>It makes database maintenance easier.</li>
          </ul>
        </b-card>
        <b-card header-tag="header">
          <template #header>
            <h4>Delivery Address Formatting API example</h4>
          </template>
          <p>
            The Delivery API converts an address string into separate address
            lines.
          </p>
          <h5>Original address string example</h5>
          <p>
            Address: 446 Rigel Avenue South, Erasmusrand, Pretoria, Gauteng,
            South Africa, 0181
          </p>
          <h5>'Delivery API’ returned address</h5>
          <p class="m-0">Address_line_1: 446 Rigel Avenue South</p>
          <p class="m-0">Address_line_2: null,</p>
          <p class="m-0">Address_line_3: Erasmusrand,</p>
          <p class="m-0">Address_line_4: Pretoria,</p>
          <p class="m-0">Address_line_5: Gauteng,</p>
          <p class="m-0">Country: South Africa,</p>
          <p>Postal code: 0181</p>
        </b-card>
      </b-col>
      <!--Column 3 -->
      <b-col lg="4">
        <b-card class="mb-3" header-tag="header">
          <template #header>
            <h4>Search Result Sample Response</h4>
          </template>
          <b-card-text>
            <b-form-textarea
              plaintext
              no-resize
              v-model="jsonSampleStr"
              style="height: 400px"
            >
            </b-form-textarea>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <v-tour
      class="deliveryTour"
      name="deliveryTour"
      :options="tourOptions"
      :steps="deliveryTourSteps"
    />
    <b-modal
      id="modal-1"
      title="Share This App"
      ref="fallback-modal"
      @ok="handleOk"
    >
      <b-form-textarea
        id="share-app-fallback"
        ref="toCopy"
        v-model="shareUrl"
        rows="1"
        max-rows="6"
      ></b-form-textarea>
      <template #modal-footer="{ ok }">
        <b-button size="sm" variant="primary" @click="ok()">
          Copy URL to Clipboard
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import VueTour from 'vue-tour';
import Vue from 'vue';

import { mapGetters } from 'vuex';
import AgAutocomplete from '@afrigis/afrigis-vue-autocomplete';
import {
  BAlert,
  BButton,
  BCard,
  BCardText,
  BCol,
  BContainer,
  BFormTextarea,
  BIconShare,
  BRow,
} from 'bootstrap-vue';

import deliveryDetails from '@/components/Details.vue';
import addressSearch from '@/mixins/addressSearch';
import oidcAuth from '@/mixins/oidcAuth';
import ContactExpert from '@/components/ContactExpert.vue';

const backgroundImage = require('@/assets/TTB_Image_1920x300.webp');

Vue.use(VueTour);

export default {
  components: {
    AgAutocomplete,
    BAlert,
    BButton,
    BCard,
    BCardText,
    BCol,
    BContainer,
    BFormTextarea,
    BIconShare,
    BRow,
    ContactExpert,
    deliveryDetails,
  },
  computed: {
    ...mapGetters({
      Suggestions: 'addressSearchStore/suggestions',
    }),
    backgroundImage: () => backgroundImage,
    deliveryTourSteps() {
      return [
        {
          content:
            'Use this input field to type in the address you want to find and click on the Search button. After a search, the matching suggested addresses will be shown displayed below the Search Box.',
          header: {
            title: 'Address Search Input Field',
          },
          params: {
            placement: 'auto',
          },
          target: '#autocomplete',
        },
        {
          content:
            'The AfriGIS Places API - Physical Delivery API uses the SEOID returned via an Autocomplete Search, to return an address formatted to be broken down into separate address lines based on information from AfriGIS address datasets. The purpose of this is to assist in generating physical and postal address details for the use in service or product delivery.',
          header: {
            title: 'Detail Description',
          },
          params: {
            placement: 'auto',
          },
          target: '#result',
        },
      ];
    },
    shareUrl() {
      const { protocol, host, pathname } = window.location;
      const url = [`${protocol}//${host}${pathname}`];
      if (this.searchText) {
        url.push(`?query=${this.searchText}`);
      }
      return url.join('');
    },
    tourOptions() {
      return {
        highlight: true,
        labels: {
          buttonSkip: 'Skip tour (Esc)',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Thanks',
        },
        useKeyboardNavigation: true,
      };
    },
  },
  data() {
    return {
      searchText: '',
      searchError: null,
      jsonSampleStr: {
        result: {
          place_id: '2XIAs5De9f_eEXNFubwV-ZXI41F281017',
          seoid: '2XIAs5De9f_eEXNFubwV-ZXI41F281017',
          sfid: '5c3659ba-335b-5ebd-e053-0d05000aa120',
          delivery: {
            address_line_1: '446 Rigel Avenue South',
            address_line_2: null,
            address_line_3: 'Erasmusrand',
            address_line_4: 'Pretoria',
            address_line_5: 'Gauteng',
            country: 'South Africa',
            postal_code: '0181',
          },
          formatted_address:
            '446 Rigel Avenue South, Erasmusrand, Pretoria, Gauteng, 0181',
          location: {
            lat: -25.808699,
            lng: 28.256111,
          },
          types: ['street_address_level_1', 'street_address'],
        },
        code: 200,
        message: 'OK',
        source: 'places.api-delivery',
      },
    };
  },
  destroyed() {
    window.removeEventListener('iris:share-capability', this.shareViaWebShare);
    window.removeEventListener('iris:start-tour', this.startTour);
  },
  methods: {
    clearAddress() {
      this.searchText = '';
      this.seoId = null;
      this.clearDetailedResults();
      this.clearSuggestions();
    },
    clearError() {
      this.searchError = null;
    },
    isTourOn() {
      const { tour } = this.$route.query;
      if (!tour) {
        return false;
      }
      return /^true$/i.test(tour.toLowerCase());
    },
    handleOk() {
      const Url = this.$refs.toCopy;
      Url.innerHTML = this.shareUrl;
      Url.select();
      document.execCommand('copy');
      this.$bvModal.msgBoxOk('URL Copied to Clipboard successfully', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
    shareViaWebShare() {
      if (navigator.share) {
        navigator
          .share({
            title: document.title,
            url: this.shareUrl,
          })
          .then()
          .catch(() => {
            // Handle the error!
          });
      } else {
        this.$refs['fallback-modal'].show();
      }
    },
    onChosen(data) {
      this.clearError();
      this.clearAddress();
      this.clearDetailedResults();
      this.clearSuggestions();
      this.searchText = data.description;
      this.seoId = data.seoid;
      this.doDelivery(this.seoId);
    },
    startAutocomplete(data) {
      this.clearError();
      this.autocomplete(data);
    },
    startSearch(data) {
      this.fetchAwsCredentials();
      this.doSearch(data);
    },
    startTour() {
      this.$tours.deliveryTour.start();
    },
    tourConfigure() {
      const hasSeenTour = JSON.parse(
        localStorage.getItem('hasSeenDeliveryTour'),
      );
      if (!hasSeenTour || this.isTourOn()) {
        this.startTour();
        localStorage.setItem('hasSeenDeliveryTour', true);
      }
    },
  },
  mixins: [addressSearch, oidcAuth],
  mounted() {
    window.addEventListener('iris:share-capability', this.shareViaWebShare);
    window.addEventListener('iris:start-tour', this.startTour);
    const { query } = this.$route.query;
    if (query) {
      this.searchText = query;
    }
    this.tourConfigure();
  },
  watch: {
    Suggestions(newV) {
      if (!newV) {
        return;
      }
      this.clearError();
    },
  },
  name: 'Delivery',
};
</script>

<style src="vue-tour/dist/vue-tour.css"></style>
<style scoped>
#fallback {
  pointer-events: none;
  background-color: lightgrey;
}
.overlay {
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.deliveryTour {
  z-index: 500;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
.resultBackground {
  background-color: #addbe5;
  color: #033a63;
}
.autocomplete-component .list-group {
  position: absolute;
}
@media screen and (max-width: 400px) {
  .appCtrlButtons {
    display: none;
  }
}
#trust-us-card {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 250px;
  border-bottom: 0;

  border-color: white;
}
#trust-us-card .content {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}
</style>
