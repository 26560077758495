import '@/set-public-path';
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';

import App from '@/App.vue';
import router from '@/router';
import { ModalPlugin } from 'bootstrap-vue';
import store from '@/store';
import Axios from 'axios';

import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(ModalPlugin);
Vue.config.productionTip = false;

Axios.interceptors.request
  .use(
    (config) => {
      const authorisationHeader = `Bearer ${store.getters['authStore/OidcAccessToken']}`;
      const { headers } = config;
      headers.Authorization = authorisationHeader;
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          // single-spa props are available on the "this" object.
          // Forward them to your component as needed.
          // https://single-spa.js.org/docs/building-applications#lifecyle-props
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
        },
      });
    },
    router,
    store,
  },
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
