<template>
  <dl class="row" v-if="isNotEmptyObject">
    <dt :class="termClasses">Address Line 1</dt>
    <dd :class="definitionClasses">{{ deliveryDetails.address_line_1 }}</dd>
    <dt :class="termClasses">Address Line 2</dt>
    <dd :class="definitionClasses">{{ deliveryDetails.address_line_2 }}</dd>
    <dt :class="termClasses">Address Line 3</dt>
    <dd :class="definitionClasses">{{ deliveryDetails.address_line_3 }}</dd>
    <dt :class="termClasses">Address Line 4</dt>
    <dd :class="definitionClasses">{{ deliveryDetails.address_line_4 }}</dd>
    <dt :class="termClasses">Address Line 5</dt>
    <dd :class="definitionClasses">{{ deliveryDetails.address_line_5 }}</dd>
    <dt :class="termClasses">Country</dt>
    <dd :class="definitionClasses">{{ deliveryDetails.country }}</dd>
    <dt :class="termClasses">Postal Code</dt>
    <dd :class="definitionClasses">{{ deliveryDetails.postal_code }}</dd>
  </dl>
</template>

<script>
export default {
  computed: {
    termClasses: () => [
      'col-6',
      'font-weight-bold',
      'text-right',
    ],
    definitionClasses: () => [
      'col-6',
      'text-left',
    ],
  },
  methods: {
    isNotEmptyObject() {
      return Object.keys(this.deliveryDetails).length;
    },
  },
  name: 'Details',
  props: {
    deliveryDetails: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
